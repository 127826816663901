<template>
<div style="height: 100%; width: 100%;">

  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; width: 100%;">
    <v-card
      style="width: 85%;"
      class="d-inline-block mx-auto"
      elevation="20"
      outlined
    >
      <v-card-text>
        <div></div>
        <p class="display-1 text--primary">
          ATS Driver
        </p>
        <p>Taurussoft</p>
        <div class="text--primary">
        </div>
      </v-card-text>

      <div style="padding-left: 8px; padding-right: 8px;">
        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
        ></v-text-field>

        <v-text-field
          v-model="password"
          :rules="passwordRules"
          label="Password"
          :type="'password'"
          required
        ></v-text-field>
      </div>

      <v-card-actions>
        <v-btn text @click="login" :disabled="submit_btn_disabled" color="primary">Login</v-btn>
      </v-card-actions>
    </v-card>
  </div>


  <v-snackbar
    v-model="snackbar"
    :timeout="snackbar_timeout"
  >
    {{ snackbar_txt }}
    <v-btn
      style="display: none;"
      color="pink"
      text
      @click="snackbar = false"
    >
      Close
    </v-btn>
  </v-snackbar>

</div>
</template>

<script>
import {EH_MOBILE_GLOBAL} from '@/helper.js'
import { post } from '@/utils/request.js'

export default {
  data () {
    return {
      email: '',
      password: '',
      snackbar: false,
      snackbar_txt: '',
      snackbar_timeout: 1500,
      submit_btn_disabled: false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
      ],
    }
  },
  methods: {
    login: function () {
      let param = 'email=' + this.email + '&password=' + this.password
      this.submit_btn_disabled = true
      post(EH_MOBILE_GLOBAL.login_url, param, {}).then((response) => {
        this.submit_btn_disabled = false
        let data = response.data
        if (data.status !== 'success') {
          this.snackbar_txt = data.reason
          this.snackbar = true
        } else {
          this.$router.push({
            path: '/tms/route/list'
          })
        }
      }).catch((error) => {
        this.submit_btn_disabled = false
        console.log(error);
      })
    },
    testLogin: function () {
      post(EH_MOBILE_GLOBAL.login_url, '', {}).then((response) => {
        let data = response.data
        if (data.status === 'success') {
          this.$router.push({
            path: '/tms/route/list'
          })
        }
      })
    }
  },
  created () {
    this.testLogin()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page-title {
  margin-top: 140px;
}
.login-form {
  margin-top: 90px;
}
.submit-btn {
  margin-top: 50px;
}
</style>
